import { Trans } from '@lingui/react'
import { Button } from '@mui/material'
import { useRouter } from 'next/router'

export function ProductSignInButton() {
  const router = useRouter()

  return (
    <Button
      color='primary'
      variant='text'
      onClick={async (e) => {
        e.preventDefault()

        await router.push('/account/signin')
      }}
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Trans id='Log in for prices' />
    </Button>
  )
}
